import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LoginForm.css';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [turnstileToken, setTurnstileToken] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
        script.async = true;
        document.body.appendChild(script);

        window.onTurnstileCallback = (token) => {
            setTurnstileToken(token);
        };
    }, []);

    // Function to get a cookie's value by name
    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };

    // Function to set the authToken cookie if it's not already set or needs updating
    const setAuthToken = (token: string): void => {
        const currentToken = getCookie('authToken');

        if (currentToken !== token) {
            document.cookie = `authToken=${token}; path=/; domain=.ezymeta.com; secure; SameSite=None`;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('/auth', {
                username,
                password,
                'cf-turnstile-response': turnstileToken
            });
            if (response.data.token) {
		localStorage.setItem('token', response.data.token);
		setAuthToken(response.data.token);
		window.location.href = 'https://mindsdb.ezymeta.com';
            } else {
		setMessage('Validation failed!')
                alert('Invalid login credentials or captcha');
            }
        } catch (error) {
	    const responseData = error.response.data;
	    setMessage(typeof responseData === 'string' ? responseData: responseData.message);
            console.error('There was an error!', error);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <div className="cf-turnstile"
                    data-sitekey="0x4AAAAAAAgegbPieQB5uoQb"
                    data-callback="onTurnstileCallback"
	    	    data-theme="light"
                ></div>
	    	<p className="error">{message}</p>
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default LoginForm;
